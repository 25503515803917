import React from "react"
import { Content, Layout, SEO } from "../components"

const Projects = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <Content>
        <h1>My projects</h1>

        <h2>
          <a href="https://eyedropper.org">Eye Dropper browser extension</a>
        </h2>
        <p>
          If you ever need to pick color from any website,{" "}
          <a href="https://eyedropper.org">Eye Dropper</a> is here to help.
        </p>
      </Content>
    </Layout>
  )
}

export default Projects
